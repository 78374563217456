<template>
  <div class="singleDetaile">
    <!-- 导航栏 -->
    <van-nav-bar
      title="chi tiết của từng bản ghi"
      left-arrow
      @click-left="$router.back()"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >chi tiết của từng bản ghi</span
        >
      </template>
      <!-- <van-button slot="right" type="default">
        <i class="iconfont icon-jia" style="color: #690f0f"></i>
        <span>Post</span>
      </van-button> -->
    </van-nav-bar>

    <div class="list">
      <div class="li">
        <!-- language-ch 注单号 -->
        <span class="item-title">cược số</span>
        <span class="item-value">{{ item.id }}</span>
      </div>
      <div class="li">
        <span class="item-title">thời gian cá cược</span>
        <div class="item-line"></div>
        <span class="item-value">{{
          (item.indate * 1000) | formatTime('MM-DD HH:mm:ss')
        }}</span>
      </div>
      <div class="li">
        <span class="item-title">loại màu</span>
        <!-- <img
          class="color-of-img"
          :src="
            require(`@/assets/images/user_order/${getColorOfimg(item.type)}`)
          "
        /> -->
        <span class="item-value color-of">{{ item.typeCn }}</span>
      </div>
      <div class="li">
        <span class="item-title">vấn đề</span>
        <span class="item-value">{{ item.expect }}</span>
      </div>
      <div class="li">
        <span class="item-title">Kiểu chơi</span>
        <span class="item-value">{{ item.methodCn }}</span>
      </div>
      <div class="li">
        <span class="item-title">số trúng thưởng</span>
        <div v-if="item.status === '已开奖'" class="code-lists">
          <!-- <template v-for="(code, index) in item.openCode">
            <span
              class="lottery-info-code-text"
              v-if="index !== item.openCode.length - 1"
              :key="index"
              >{{ code }}</span
            >
            <img
              class="code-img"
              v-else
              :key="index"
              style="
                 {
                  'width':'50px','height': '50px';
                }
              "
              :src="require(`@/assets/images/lottery_history/${code}.png`)"
            />
          </template> -->
          <span class="code-lists-value colorText" v-if="item.type === 'hl'">{{
            substrEvent(item.openCode)
          }}</span>
          <span class="code-lists-value colorText" v-else>{{
            item.openCode
          }}</span>
        </div>
        <!-- <span v-else class="item-value">{{ stateObj[item.status] }}</span> -->
        <span v-else class="item-value">{{ stateObj[item.status] }}</span>
      </div>
      <div class="li">
        <p class="item-title new-item-title" style="">kết quả</p>
        <div v-if="item.status === '已开奖'" class="code-lists new-code-lists">
          <!-- <span
            class="code-lists-value colorText"
            v-for="(item, index) in resultColor(item.openCode)"
            :key="index"
            :style="{ color: item.color }"
            >{{ item.colorText }}</span
          >
          <div
            class="code-lists-ball"
            v-for="(item, i) in resultColor(item.openCode)"
            :key="i"
            :style="{ backgroundColor: item.color }"
          ></div> -->
          <span class="code-lists-value colorText">{{ item.openMethod }}</span>
        </div>
        <span class="item-value" v-else>{{ stateObj[item.status] }}</span>
      </div>
      <div class="li">
        <!-- 投注号码 -->
        <span class="item-title">cược số</span>

        <div class="code-lists">
          <!-- <span
            class="code-lists-value colorText"
            v-for="(item, index) in formatStatus(item.openCode, item.status)"
            :key="index"
            :style="{ color: item.color }"
            >{{ item.bettingText }}</span
          > -->
          <!-- <span class="code-lists-value colorText">{{
            formatStatus2(item.openCode, item.status)
          }}</span> -->
          <span class="code-lists-value colorText">{{ item.code }}</span>
        </div>
      </div>
      <div class="li">
        <span class="item-title">tỷ lệ cược </span>
        <!-- <span class="item-value-red">{{
          item.odds.length ? item.odds[item.method] : ''
        }}</span> -->
        <span class="item-value-red">{{
          formatOdds(item.odds, item.method)
        }}</span>
      </div>
      <div class="li">
        <span class="item-title">số tiền đặt cược duy nhất</span>
        <span class="item-value-red"
          >₫ {{ formatMoney(item.money, item.mtype) }}</span
        >
      </div>
      <div class="li">
        <span class="item-title">tổng số tiền đặt cược</span>
        <span class="item-value-red">{{ item.num }}</span>
      </div>
      <div class="li">
        <span class="item-title">chi phí</span>
        <span class="item-value-red">₫ 0</span>
      </div>
      <div class="li">
        <span class="item-title">trạng thái</span>
        <span
          v-if="item.status === '已关闭' && item.error !== ''"
          class="item-value-red"
          >{{ stateObj[item.status] }}</span
        >
        <span v-else class="item-value-green">{{ stateObj[item.status] }}</span>
      </div>
      <div class="li">
        <span class="item-title">số tiền thắng cuộc</span>
        <span class="item-value-red">+₫ {{ item.winMoney }}</span>
      </div>
      <div class="li">
        <span class="item-title">lợi nhuận và thua lỗ</span>
        <span v-if="item.status !== '已开奖'" class="item-value">{{
          stateObj[item.status]
        }}</span>
        <span
          v-else-if="winMoney(item.winMoney, item.totalMoney) <= 0"
          class="item-value-green"
          >{{ winMoney(item.winMoney, item.totalMoney) }}</span
        >
        <span v-else class="item-value-red"
          >+{{ winMoney(item.winMoney, item.totalMoney) }}</span
        >
      </div>
    </div>

    <div class="image">
      <img
        v-if="item.status === '已关闭' && item.error === '用户撤销'"
        class="foot-image"
        src="/src/assets/images/user_order/retreat.png"
        autoBitmapRecycle="false"
      />
      <img
        v-else-if="item.status === '已开奖'"
        class="foot-image"
        :src="
          require(`@/assets/images/user_order/${
            runMoney(item.winMoney) > 0 ? 'winning' : 'unwinning'
          }.png`)
        "
        autoBitmapRecycle="false"
      />
    </div>
    <div class="floot">
      <van-button
        type="danger"
        class="btn"
        @click="$router.push('/user/order2')"
        >tiếp tục mua</van-button
      >
    </div>
  </div>
</template>

<script>
import { getLotteryDetail } from '@/api/lottery.js'
import { count } from '@/utils/unique.js'
import { runNum } from '@/utils/method.js'
import { Dialog } from 'vant'

export default {
  name: 'SingleDetaile',
  components: {},
  data() {
    return {
      stateObj: {
        待开奖: 'Chưa mở thưởng',
        已开奖: 'Đã kết toán',
        开奖出错: 'Xổ số lỗi',
        已关闭: 'Xổ số lỗi',
        正在开奖: 'Cuộc xổ số đang được tiến hành.',
        开奖中: 'Đang kết toán',
        已撤单: 'hủy bỏ một đơn đặt hàng',
      },

      //   判断是否中奖的图片
      id: '',
      item: {},
    }
  },
  mounted() {},
  created() {
    // let item = JSON.parse
    // //  获取每一项的详情
    this.item = JSON.parse(this.$route.query.singItem)
    this.id = this.item.id
    this.getItemDetail()
  },
  methods: {
    async getItemDetail() {
      const {
        data: { ret, data },
      } = await getLotteryDetail({ id: this.id })
      if (ret === 1) {
        this.item = data.lists[0]
      }
    },

    formatStatus(value, stauts) {
      // if (this.params.type !== 'hl') {
      //   return stauts === '已开奖' ? value : stauts
      // } else {
      return stauts === '已开奖'
        ? value
          ? value.substr(value.length - 1, 1)
          : value
        : stauts
      // }
    },

    // 判断最后一位数字和颜色,返回数字和颜色
    //   1379绿色  2468红色 5绿色加紫色 0红色加紫色
    //   1379绿色  2468红色 5绿色加紫色 0màu đỏ色加紫色
    resultColor(numbers) {
      
      if (!numbers) return []
      if (typeof numbers !== 'string') {
        numbers = numbers.toString()
      }
      let number = numbers[numbers.length - 1]

      const color = ['#13cc00', '#ff0000', '#b400ff']
      const colorText = ['Green', 'Red', 'Violet']
      let colorStr = [{ number, color: '', colorText: '', bettingText: '' }]

      // 下注号码为中文的情况 下注颜色 绿 紫 红
      if (numbers === 'màu tía') {
        return [
          {
            number,
            color: color[2],
            colorText: colorText[2],
            bettingText: colorText[2],
          },
        ]
      }
      if (numbers === 'màu xanh lá') {
        return [
          {
            number,
            color: color[0],
            colorText: colorText[0],
            bettingText: colorText[0],
          },
        ]
      }
      if (numbers === 'màu đỏ') {
        return [
          {
            number,
            color: color[1],
            colorText: colorText[1],
            bettingText: colorText[1],
          },
        ]
      }

      switch (number) {
        case 'màu xanh lá':
          number = 1
          break
        case 'màu đỏ':
          number = 2
          break
      }

      if (number % 2 == 0) {
        colorStr = [
          {
            number,
            color: color[1],
            colorText: colorText[1],
            bettingText: number,
          },
        ]
        // colorStr = [{ number, color: color[1], colorText: number }]
      } else if (number % 2 != 0) {
        colorStr = [
          {
            number,
            color: color[0],
            colorText: colorText[0],
            bettingText: number,
          },
        ]
        // colorStr = [{ number, color: color[0], colorText: number }]
      }

      if (number == 5) {
        colorStr = [
          {
            number,
            color: color[0],
            colorText: colorText[0],
            bettingText: number,
          },
          { number, color: color[2], colorText: colorText[2], bettingText: '' },
        ]
      }

      if (number == 0) {
        colorStr = [
          {
            number,
            color: color[1],
            colorText: colorText[1],
            bettingText: number,
          },
          { number, color: color[2], colorText: colorText[2], bettingText: '' },
        ]
      }

      return colorStr
    },
    cancelOrder() {
      if (this.canCancel !== true) {
        return
      }

      this.$dialog
        .confirm({
          title: 'hủy bỏ để đặt cược',
          message: 'Bạn có chắc chắn muốn hủy bỏ đặt cược này không???',
          cancelButtonText: 'Hủy bỏ',
          confirmButtonText: 'xác nhận',
          confirmButtonColor: '#00aa88',
        })
        .then(() => {
          this.apiAjax(
            {
              url: 'lottery/order/close',
              data: {
                orderid: this.params.id,
              },
            },
            (res) => {
              this.$toast(res.msg)
            }
          )
        })
        .catch(() => {
          // on cancel
        })
    },

    runMoney(money) {
      return runNum(money)
    },

    continueBuy(type) {
      eeui.openPage({
        url: 'room.js',
        pageType: 'app',
        statusBarColor: '#DC1323',
        params: {
          type: type,
        },
      })
    },

    // 设置彩种图片
    getColorOfimg(type) {
      let img = ''
      switch (type) {
        case 'hl1':
          img = 'Fast.png'
          break
        case 'hl3':
          img = 'Sapre.png'
          break
        case 'hl5':
          img = 'Bcone.png'
          break
        case 'hl10':
          img = 'Emerd.png'
          break
        case 'hl':
          img = 'Emerd.png'
          break
      }
      return img
    },

    formatMoney(money, mtype) {
      if (count(money) === 0) {
        return ''
      }
      if (mtype === 'jiao') {
        money *= 0.1
      }
      return runNum(money, 2)
    },

    // formatStatus (value, stauts) {
    //   return stauts === 'vẽ' ? value : stauts
    // },

    winMoney(winMoney, totalMoney) {
      if (count(winMoney) === 0 || count(totalMoney) === 0) {
        return ''
      }
      return runNum(winMoney - totalMoney, 2)
    },
    substrEvent(val) {
      if (val) {
        return val.substr(val.length - 1, 1)
      } else {
        return '/'
      }
    },
    // formatStatus (value, stauts) {
    //   if (this.params.type !== 'hl') {
    //     return stauts === 'vẽ' ? value : stauts
    //   } else {
    //     return stauts === 'vẽ'
    //       ? value
    //         ? value.substr(value.length - 1, 1)
    //         : value
    //       : stauts
    //   }
    // },
    formatStatus2(value, stauts) {
      // if (this.params.type !== 'hl') {
      //   return stauts === '已开奖' ? value : stauts
      // } else {
      if (stauts === '已开奖') {
        if (value) {
          if (typeof value === 'number') {
            return value
          } else {
            return this.infoTextShow(value.join())
          }
        } else {
          return stauts
        }
      } else {
        return stauts
      }
      // }
    },
    methodCnText(text) {
      // if (this.params.type === 'hl') {
      //   if (text === '定位胆') {
      //     return '数字竞猜'
      //   } else {
      //     return '动物竞猜'
      //   }
      // } else {
      return text
      // }
    },

    formatOdds(odds, method) {
      
      
      if (count(odds) === 0) {
        return ''
      }
      return odds[method]
    },
    infoTextShow(text) {
      if (text === 'màu xanh lá') {
        return 'gấu trúc'
      } else if (text === 'màu tía') {
        return 'Rồng'
      } else if (text === 'màu đỏ') {
        return 'con hổ'
      } else {
        return text
      }
    },
  },
}
</script>

<style lang="less" scoped>
.singleDetaile {
  position: relative;
  .red {
    color: #ff0000 !important;
  }
  .green {
    color: #93e618 !important;
  }
  .van-nav-bar {
    // background-color: #ff0000;
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    /deep/ .van-icon-arrow-left::before {
      // color: #fff;
    }
    .van-button {
      width: 140px;
      height: 60px;
      border-radius: 10px;
      color: #690f0f;
      font-size: 30px;
      padding: 0;
      .iconfont {
        font-size: 40px;
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  }
  .image {
    position: absolute;
    bottom: -80px;
    right: 0;
    width: 150px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .danger {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    color: #fff;
  }
  .list {
    width: 100%;
    margin-bottom: 96px;
    min-height: 60px;
    .li {
      /* background-color: #ffffff; */
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      span {
        width: 50%;
        height: 100%;
        line-height: 60px;
        display: inline-block;
        box-sizing: border-box;
        &:nth-child(1) {
          border-right: 1px solid #ccc;
        }
      }
    }
    .item-title {
      color: #000;
      font-size: 24px;
      padding-left: 48px;
    }
    .new-item-title {
      width: 50%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border-right: 1px solid #ccc;
      min-height: 60px;
    }
    .item-value,
    .item-value-green,
    .item-value-red {
      flex: 1;
      color: #242424;
      font-size: 30px;
      padding-left: 36px;
    }
    .color-of {
      padding-left: 16px;
      padding-left: 36px;
    }

    .color-of-img {
      width: 45px;
      height: 45px;
      margin-left: 36px;
    }
    .new-item-title {
      width: calc(50% - 1px);
      border: 0;
    }
    .code-lists {
      width: calc(50%);
      display: flex;
      align-items: center;
      padding-left: 36px;
      box-sizing: border-box;
    }
    .new-code-lists {
      width: calc(50% + 1px);
      min-height: 60px;
      border-left: 1px solid #ccc;
    }

    .code-lists-value {
      flex: 1;
      color: #242424;
      font-size: 30px;
      padding-left: 10px;
    }
    .colorText {
      border-right: 0 !important;
      margin-right: 5px;
    }

    .lottery-info-code-text {
      width: 50px !important;
      height: 50px !important;
      line-height: 50px !important;
      text-align: center;
      margin-right: 7px;
      color: #ffffff;
      font-size: 29px;
      border-radius: 50px;
      background-color: #dc1323;
      // background-color: #4800ff;
    }

    .code-img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .code-lists-ball {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      margin-right: 6px;
    }

    .item-value-green {
      color: #13cc00;
    }
    .item-value-red {
      color: #ff0000;
    }
  }
  .floot {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      flex: 1;
      border: none;
    }
    .blue {
      background-color: #3fa0f0;
    }
  }
}
</style>
